<template>
  <div class="row">
    <div class="col-lg-5">
      <div class="form-group">
        <label for="name">اسم التصنيف</label>
        <Field
            name="name"
            id="name"
            :value="categoryDetails.name"
            @input="$emit('update', 'name', $event.target.value)"
            placeholder="اسم التصنيف"
            class="form-control form-control-solid"
        />
      </div>
      <div class="form-group mt-3">
        <label for="parentCategory" class="d-block">التصنيف الأساسي</label>
        <el-select id="parentCategory" class="w-100 custom-height" v-model="parent_id" filterable placeholder="Select">
          <el-option
              v-for="item in categories"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-group mt-3">
        <label for="description">وصف التصنيف</label>
        <textarea
            rows="5"
            :value="categoryDetails.description"
            @input="$emit('update', 'description', $event.target.value)"
            name="description"
            id="description"
            class="form-control input-rounded form-control-solid"
            placeholder="وصف التصنيف"
        ></textarea>
        <span class="hint">* يجب ان لا يتجاوز وصف التصنيف 230 حرفا</span>
      </div>

      <div class="form-group mt-3" v-if="isEditable">
        <label>الترتيب</label>
        <select class="form-select sort" v-model="sort">
          <option value=""></option>
          <option v-for="sort in sorts" :key="sort" :value="sort">{{sort}}</option>
        </select>
      </div>


      <div class="d-flex flex-wrap mt-3">
        <el-checkbox
            :value="categoryDetails.show_in_home"
            @input="$emit('update', 'show_in_home', !categoryDetails.show_in_home)"
            :checked="categoryDetails.show_in_home === 1"
            label="إظهار في الرئيسية؟" border
        ></el-checkbox>
        <el-checkbox
            :value="categoryDetails.show_in_nav"
            @input="$emit('update', 'show_in_nav', !categoryDetails.show_in_nav)"
            :checked="categoryDetails.show_in_nav === 1"
            label="إظهار بشريط العناوين؟" border>
        </el-checkbox>
        <el-checkbox
            :value="categoryDetails.is_english"
            @input="$emit('update', 'is_english', !categoryDetails.is_english)"
            :checked="categoryDetails.is_english === 1"
            label="?English" border></el-checkbox>
        <el-checkbox
            :value="categoryDetails.is_active"
            @input="$emit('update', 'is_active', !categoryDetails.is_active)"
            :checked="categoryDetails.is_active === 1"
            label="تفعيل؟" border></el-checkbox>
      </div>

      <div class="form-group mt-4">
        <div class="demo-color-block">
          <label class="demonstration">لون التصنيف</label>
          <el-color-picker v-model="color"></el-color-picker>
        </div>
      </div>



    </div>
    <div class="col-md-3 mt-1">
      <div class="form-group">
        <label>الصورة</label>
        <label class="placeholder-image mt-0" @click="showUploadCenter=true;isSingleUpload=true">
          <img class="w-100" height="220" v-if="photo" :src="APP_URL + photo" alt="">
        </label>
      </div>
      <el-dialog :destroy-on-close=true
                 title="مركز الرفع"
                 v-model="showUploadCenter"
      >
        <UploadCenter :is-single-upload="isSingleUpload" @save="handleAddPhoto"/>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import UploadCenter from "@/components/uploadCenter";
import {getNewsCategories} from "@/services/news";
import {Field} from "vee-validate";
import {getCategoriesSort} from "@/services/categories";

export default {
  name      : "Details",
  props     : ["categoryDetails","isEditable"],
  components: {
    Field,
    UploadCenter
  },
  data()
  {
    return {
      categories      : [],
      sorts: [],
      sort:1,
      showUploadCenter: false,
      isSingleUpload  : false,
      photo           : '',
      parent_id       : '',
      color: '#000',
    }
  },
  watch   : {
    parent_id(newVal)
    {
      this.$emit('update', 'parent_id', newVal)
    },
    color(newVal) {
      this.$emit('update', 'color', newVal)
    },
  },
  computed: {
    APP_URL: function () {
      return process.env.VUE_APP_IMAGE_REMOTE_URL
    },
  },
  mounted()
  {
    this.fetchCategories();
    if(this.isEditable) {
      this.fetchSorts();
    }
  },
  methods: {
    fetchCategories()
    {
      getNewsCategories()
          .then((res) => {
            this.categories = res.data;
          })
          .catch((e) => {
            console.log(e);
          });
    },
    fetchSorts(){
      getCategoriesSort()
      .then((res)=>{
        this.sorts = res.data
      })
      this.parent_id = this.categoryDetails.parent_id;
      this.sort = this.categoryDetails.sort;
      this.photo = this.categoryDetails.image;
      this.color = this.categoryDetails.color;
    },
    closeUploadCenter()
    {
      this.showUploadCenter = false;
      this.isSingleUpload   = false;
    },
    handleAddPhoto(photo)
    {
      if (this.showUploadCenter)
      {
        this.photo = photo.url;
        this.$emit('update', 'image', photo.url)
      }
      this.closeUploadCenter();
    }
  }
}

</script>

<style lang="scss">
.custom-height {

  .el-input__inner {
    height: 42px;

  }
}

[dir=rtl] .el-color-hue-slider {
  float: left;
}

[dir=rtl] .el-button+.el-button {
  margin-right: 12px;
}

.el-color-picker__trigger {
  width: 50px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.el-checkbox.is-bordered {
  margin: 0;
  border-radius: 0;
  height: 42px;
}

.el-checkbox__label {
  padding-right: 8px;

}
</style>
