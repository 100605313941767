<template>
  <div class="row">
    <div class="col-md-5">
      <div class="form-group">
        <label for="slug">الاسم اللطيف</label>
        <Field
            name="slug"
            id="slug"
            :value="seoData.seo_name"
            @input="$emit('update', 'seo_name', $event.target.value)"
            placeholder="الاسم اللطيف"
            class="form-control form-control-solid"
        />
        <span class="hint">* يوصى ان يحتوي الاسم اللطيف على 5 كلمات كحد اقصى</span>
      </div>

      <div class="form-group mt-3">
        <label for="seo_title">عنوان التصنيف</label>
        <Field
            name="seo_title"
            id="seo_title"
            :value="seoData.seo_title"
            @input="$emit('update', 'seo_title', $event.target.value)"
            placeholder="عنوان التصنيف"
            class="form-control form-control-solid"
        />
        <span class="hint">* يجب ان لا يتجاوز عنوان التصنيف 60 حرفا</span>
      </div>

      <div class="form-group mt-3">
        <label for="seo_description">وصف التصنيف</label>
        <textarea
            rows="5"
            :value="seoData.seo_description"
            @input="$emit('update', 'seo_description', $event.target.value)"
            name="seo_description"
            id="seo_description"
            class="form-control input-rounded form-control-solid"
            placeholder="وصف التصنيف"
        ></textarea>
        <span class="hint">* يجب ان لا يتجاوز وصف التصنيف 230 حرفا</span>
      </div>
    </div>
  </div>
</template>

<script>
import {  Field, ErrorMessage } from "vee-validate";

export default {
  name: "SEO",
  components: {
    Field
  },
  props: ["seoData"],

};
</script>
