<template>
  <div class="row">
    <div class="col-md-5">
      <div class="form-group">
        <label for="social_title">عنوان التصنيف</label>
        <Field
            name="social_title"
            id="social_title"
            :value="socialData.social_title"
            @input="$emit('update', 'social_title', $event.target.value)"
            placeholder="عنوان التصنيف"
            class="form-control form-control-solid"
        />
        <span class="hint">* يجب ان لا يتجاوز عنوان التصنيف 60 حرفا</span>
      </div>

      <div class="form-group mt-3">
        <label for="social_description">وصف التصنيف</label>
        <textarea
            rows="5"
            :value="socialData.social_description"
            @input="$emit('update', 'social_description', $event.target.value)"
            name="social_description"
            id="social_description"
            class="form-control input-rounded form-control-solid"
            placeholder="وصف التصنيف"
        ></textarea>
        <span class="hint">* يجب ان لا يتجاوز وصف التصنيف 230 حرفا</span>
      </div>
    </div>
    <div class="col-md-3 mt-1">
      <div class="form-group">
        <label>الصورة</label>
        <label class="placeholder-image mt-0" @click="showUploadCenter=true;isSingleUpload=true">
          <img class="w-100" height="220" v-if="photo" :src="APP_URL + photo" alt="">
        </label>
      </div>
      <el-dialog   :destroy-on-close=true
                   title="مركز الرفع"
                   v-model="showUploadCenter"
      >
        <UploadCenter :is-single-upload="isSingleUpload" @save="handleAddPhoto"/>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import {  Field, ErrorMessage } from "vee-validate";
import UploadCenter from "@/components/uploadCenter";

export default {
  name: "SocialMediaTab",
  components: {
    Field,
    UploadCenter
  },
  props: ["socialData"],
  data() {
    return {
      showUploadCenter: false,
      isSingleUpload: false,
      photo: ''
    }
  },
  mounted() {
    this.photo = this.socialData.metas?.social_image || '';
  },
  computed: {
    APP_URL: function () {
      return process.env.VUE_APP_IMAGE_REMOTE_URL
    },
  },
  methods: {
    closeUploadCenter() {
      this.showUploadCenter = false;
      this.isSingleUpload = false;
    },
    handleAddPhoto(photo) {
      if (this.showUploadCenter) {
        this.photo = photo.url;
        this.$emit('update','social_image',photo.url)
      }
      this.closeUploadCenter();
    }
  }

};
</script>

<style lang="scss">
.placeholder-image {
  img.w-100 {
    object-fit: cover;
    object-position: center;
  }
}
</style>
